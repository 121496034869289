import { StaticImage } from "gatsby-plugin-image"
import React from "react"

import { pagesLinks } from "../../../pages-data/_V2/common/links"
import styles from "./styles.module.scss"

export default {
	desktopTitle: (
		<span>Автоматизация пекарни&nbsp;</span>
	),
	price: "от 2490 ₽/мес",
	subTitle:
		"Больше возможностей для увеличения прибыли и контроля за бизнесом с программой для пекарни Quick Resto. Всегда будешь знать, какую выпечку и десерты гости покупают чаще всего, сможешь запускать акции по часам и внедрять любые программы лояльности, рассчитывать зарплату и удалённо следить за работой сотрудников.",
	figure: (
		<StaticImage
			className={styles.image}
			src="./assets/bakery-presentation.png"
			alt="автоматизация пекарни"
			objectFit="contain"
			objectPosition="center"
			placeholder="blurred"
			quality={90}
		/>
	),
	cta: pagesLinks.registration.text,
	ctaLink: pagesLinks.registration,
	desktopCtaDetails:
		"За 10 минут, без дорогого оборудования, с удобным интерфейсом. И две недели бесплатно.",
}
